import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {ProductService} from "../../../service/product-service/product.service";
import * as _ from "lodash";
import {DialogService} from "../../../native/dialog/service/dialog.service";

@Component({
  selector: 'app-add-material-dialog',
  templateUrl: './add-material-dialog.component.html',
  styleUrls: ['./add-material-dialog.component.scss']
})
export class AddMaterialDialogComponent implements OnInit{
  @Input() dialog: Dialog;

  public material: any;
  public isLoading: boolean;

  public error: any;
  constructor(
      private productService: ProductService,
      private dialogService: DialogService
  ){
    this.material = {name: '', itemList: [], materialList: []};

    this.error = {};
  }

  ngOnInit(): void {
  }


  public onInputChange(name: string): void {
    this.error[name] = false;
  }

  private validateItem(): boolean {
    this.error.name = _.isEmpty(this.material.name.trim());
    this.error.itemList = _.isEmpty(this.material.itemList);
    //this.error.materialList = _.isEmpty(this.material.materialList);
    for (const key in this.error) {
      if (this.error.hasOwnProperty(key)) {
        if (this.error[key]) {
          return false;
        }
      }
    }
    return true;
  }

  onAddMaterial(): void{
    if (!this.validateItem()) { return; }
    this.dialog.close(this.material);
  }

  onAddItem(): void{
    const pickItemDialog =  this.dialogService.open('pickItemDialog', {data: {excludeIdList: this.material.itemList.map(item =>item._id)}});
    const pickItemSub = pickItemDialog.eClose.subscribe(selectedItemList => {
      if (selectedItemList) {
        const newAddItemList = selectedItemList.map(item => ({
          _id: item._id,
          name: item.name,
          unitOfMeasurement: item.unitOfMeasurement ?? '',
          qty: 1
        }));
        this.material.itemList = this.material.itemList.concat(newAddItemList);
      }
      pickItemSub.unsubscribe();
    });
  }

  onRemoveItem(item: any): void{
    this.material.itemList = this.material.itemList.filter(i => i._id !== item._id);
  }

}
