import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {

  @Input() checked: boolean;
  @Input() disable: boolean;
  @Output() checkedChange: EventEmitter<boolean>;

  @Input() label: string;

  @Input() size: number;
  @Input() fontSize: number;
  @Input() borderWidth: number;
  @Input() borderStyle: string;
  @Input() isFontBold: boolean;

  constructor() {
    this.size = 20;
    this.fontSize = 14;
    this.borderWidth = 1;
    this.borderStyle = 'solid';
    this.isFontBold = false;

    this.checkedChange = new EventEmitter<boolean>();
  }

  ngOnInit(): void {}

  onCheck(): void {
    if (!this.disable){
      this.checked = !this.checked;
      this.checkedChange.emit(this.checked);
    }
  }
}
