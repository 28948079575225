<div class="w500 h80vh fxColumn px-4 py-2">
    <div class="fullHeight fxColumn">
        <div class="mb-4 row justify-content-between align-items-center top-sec">
            <h3 class="col-11">{{ 'Add New Material' | translate }}</h3>
            <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
        </div>
        <div class="mb-3">
            <label for="form-input-subject" class="form-label">{{ 'Name' | translate }}</label>
            <input id="form-input-subject"
                   [(ngModel)]="material.name"
                   [class.is-invalid]="error.name"
                   (input)="onInputChange('name')"
                   class="form-control"
                   [placeholder]="'Name' | translate"
            />
        </div>
        <div class="mb-4 fxColumn fullHeight">
            <div class="fxRow fxColumnCenter">
                <label for="form-input-subject" class="form-label">{{ 'Item' | translate }}</label>
                <label *ngIf="error.itemList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please select at least on item' | translate }}</label>
            </div>
            <div class="scrollY p12 borderRadius"  [class.borderDanger]="error.itemList" style="height: calc(100vh - 432px)">
                <div *ngIf="material.itemList?.length > 0" class="fxRow fxRowSpaceBetween" style="gap: 12px">
                    <div class="fontSemiBold w40p pl12">{{'Name'}}</div>
                    <div class="fontSemiBold w20p pl12">{{'Qty'}}</div>
                    <div class="fontSemiBold w20p pl12">{{'Measure'}}</div>
                    <div class="w20p pl12"></div>
                </div>
                <div *ngFor="let item of material.itemList; let index = index;" class="fxRow fxColumnCenter fxRowSpaceBetween background py6 px12 borderRadius backgroundOver" [class.mb12]=" index !== material.itemList.length - 1"
                style="gap: 12px">
                    <div class="fontSemiBold w40p">{{item.name}}</div>
                    <input [(ngModel)]="item.qty" type="number"
                           [class.is-invalid]="error.qty"
                           class="form-control w20p"
                    />
                    <input [(ngModel)]="item.unitOfMeasurement"
                           [class.is-invalid]="error.unitOfMeasurement"
                           class="form-control w20p"
                    />
                    <div class="w20p fxRow fxRowCenter fxColumnCenter cursorPointer"
                         (click)="onRemoveItem(item)">
                        <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                  class="size20 colorDanger"></svg-icon>
                    </div>

                </div>
                <div class="fxRow fxColumnCenter cursorPointer gap-2 colorHoverPrimary mt24" (click)="onAddItem()" >
                    <svg-icon src="./assets/image/icon/material/add-icon.svg"></svg-icon>
                    <div class="noSelect">{{'Add Item'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="fxRow fxRowEnd">
        <button class="btn btn-solid" (click)="onAddMaterial()" [disabled]="isLoading">
            {{ 'Add' | translate }}
        </button>
        <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
    </div>
</div>
