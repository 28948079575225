import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {ProductDetailApiService} from "../../../component/shop/product-detail/product-detail/api/product-detail-api.service";
import * as _ from  'lodash';

@Component({
  selector: 'app-pick-item-dialog',
  templateUrl: './pick-item-dialog.component.html',
  styleUrls: ['./pick-item-dialog.component.scss']
})
export class PickItemDialogComponent implements OnInit{
  @Input() dialog: Dialog;

  public options = {
    allowPickMany: false,
    excludeIdList: [],
  };

  // get
  public apiItemList: any[];
  public itemList: any[];
  public itemNext: number;
  public isLoading: boolean;

  // search
  public searchText: string;

  constructor(
      private productDetailApiService: ProductDetailApiService
  ){
    // get
    this.itemList = [];
    this.apiItemList = [];

    // search
    this.searchText = '';
    this.onSearch = _.debounce(this.onSearch, 500);
  }

  ngOnInit(): void {
    if(!_.isEmpty(this.dialog.options?.data?.excludeIdList)){
      this.options.excludeIdList = this.dialog.options.data.excludeIdList;
    }
    this.getAllItem().then();
  }

  async getAllItem(): Promise<void> {
    this.isLoading = true;
    try {
      const query = {excludeIdList: this.options.excludeIdList, search: this.searchText};
      const {list, next} = await this.productDetailApiService.apiGetProductList(0, query).toPromise();
      this.apiItemList = list.filter(item => item !== null);
      this.itemNext = next;
      this.itemList = _.cloneDeep(this.apiItemList);
    } catch (e) { console.error('Get item fail!', e); }
    this.isLoading = false;
  }
  async onGetMoreItem(): Promise<void> {
    if (!this.itemNext) { return; }

    try {
      const query = {excludeIdList: this.options.excludeIdList, search: this.searchText};
      const {list, next} = await this.productDetailApiService.apiGetProductList(this.itemNext, query).toPromise();
      this.itemList = this.itemList.concat(list.filter(item => item !== null));
      this.itemNext = next;
    } catch (e) { console.error('Get more item fail!', e); }
  }

  // search
  onSearch(): void {
    this.itemList = this.apiItemList.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  onSelectItem(selectedItem): void {
    this.itemList.forEach(item => item.isSelected = false);
    selectedItem.isSelected = !selectedItem.isSelected;
  }
  getPrice(item): number{
    return item.itemSupplierList.filter(supplier => supplier.isDefault).map(supplier => supplier.supplierPrice)[0] || 0;
  }

  // action
  onPick(): void {
    this.dialog.close(_.cloneDeep(this.itemList.filter(item => item.isSelected)));
  }
  isDisable(): boolean{
    return _.isEmpty(this.itemList.filter(item => item.isSelected));
  }
  onBack(): void { this.dialog.close(); }
}


