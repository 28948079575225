import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {OrderAddress} from "../../../model/order-address/order-address";
import {AddressApiService} from "../../../service/address-api-service/address-api.service";
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-select-address-dialog',
  templateUrl: './select-address-dialog.component.html',
  styleUrls: ['./select-address-dialog.component.scss']
})
export class SelectAddressDialogComponent implements OnInit{
  @Input() dialog: Dialog;

  public orderAddressList: OrderAddress[];
  public selectedOrderAddress: OrderAddress;

  public deliveredAt: string;
  public isLoading: boolean;

  constructor(
      private addressApiService: AddressApiService,
      public userService: UserService,
  ){
    this.orderAddressList = [];
  }
  ngOnInit(): void {
    this.getOrderAddress();
  }

  // get order address
  getOrderAddress(){
    this.isLoading = true;
    try {
      this.addressApiService.getAddress().subscribe((response) => {
        this.orderAddressList = response;
        this.selectedOrderAddress = this.orderAddressList[0];
        this.isLoading = false;
      },(error)=>{
        console.error('Get Order Address fail!', error);
        this.isLoading = false;
      });
    } catch(e){
      console.error('Get Order Address fail!', e);
    }
  }

  // select order address
  onSelectOrderAddress(address: OrderAddress): void{
    this.selectedOrderAddress  = address;
  }


  onChooseAddress(): void{
    this.selectedOrderAddress.organizationId = this.userService.user.organizationId;
    const deliveryInfo = {
      deliveryAddress: this.selectedOrderAddress,
      deliveredAt: this.deliveredAt ? new Date(this.deliveredAt) : null
    };
    this.dialog.close(deliveryInfo);
  }

  onChangeDate():void{
    if (!this.deliveredAt) {
      this.deliveredAt = this.getFormatDateTimeNow();
    }
    setTimeout(() => {
      (document.querySelector('#deliveredAt') as any).showPicker();
    }, 50);
  }
  onResetDeliveryDate(): void{
    this.deliveredAt = null;
  }
  getFormatDateTimeNow(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Mon  th is zero-based
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return`${year}-${month}-${day}T${hours}:${minutes}`;
  }

}
