import { Injectable } from '@angular/core';
import {BaseService} from "../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecipeApiService {

  constructor(private baseService: BaseService) { }

  getRecipeList(next: number, limit: number): Observable<any>{
    return this.baseService.get(`/v1/user/get/recipe/${next}/${limit}`);
  }
  getRecipeById(recipeId: string): Observable<any>{
    return this.baseService.get(`/v1/user/get/recipeById/${recipeId}`);
  }
  createRecipe(body: any): Observable<any>{
    return this.baseService.post(`/v1/user/create/recipe`, body);
  }
  updateRecipe(recipeId: string, body: any): Observable<any>{
    return this.baseService.put(`/v1/user/update/recipe/${recipeId}`, body);
  }
  deleteRecipe(recipeId: string): Observable<any>{
    return this.baseService.delete(`/v1/user/delete/recipe/${recipeId}`);
  }
  refillRecipe(recipeId: string, deliveryInfo?): Observable<any>{
    return this.baseService.post(`/v1/user/refill/recipe/${recipeId}`, deliveryInfo);
  }

  // item measure setting
  getItemMeasureSetting(): Observable<any>{
    return this.baseService.get(`/v1/user/get/itemMeasureSetting`);
  }
}
