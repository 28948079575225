import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Product} from "../../../model/product/product";
import {ProductService} from "../../../service/product-service/product.service";
import {UserService} from "../../../native/user/service/user.service";

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent {
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() loader: boolean = true;
  @Input() isSelected: boolean = false;
  @Output() public eItemClick: EventEmitter<any>;

  public ImageSrc: string;

  constructor(
      public productService: ProductService,
      public userService: UserService
  ) {
    this.eItemClick = new EventEmitter<any>();
  }

  ngOnInit(): void {
  }


  getPrice(product): number {
    return product.itemSupplierList.filter(supplier => supplier.isDefault).map(supplier => supplier.supplierPrice)[0] || 0;
  }

  onClickItem(): void{
    if(!this.isSelected){
      this.eItemClick.emit({item: this.product, isAdd: true});
    }else{
      this.eItemClick.emit({item: this.product, isAdd: false});
    }
  }
}

