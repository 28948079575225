<div *ngIf="!loader && product" class="product-box product-style-5 fullHeight" (click)="onClickItem()" [class.cursorPointer]="!isSelected">
    <div class="fxRow fxRowSpaceBetween">
        <div class="img-wrapper mt-0">
            <div class="front ratio ratio-1x1 bg-white">
                <img [defaultImage]="'assets/image/img/product/placeholder.jpg'"
                     [lazyLoad]="product?.thumbnail"
                     [src]="product?.thumbnail"
                     [alt]="product.name"
                     class="img-fluid blur-up lazyload bg-img object-fit-scale">
            </div>
        </div>
        <app-check-box [disable]="true" [(checked)]="isSelected"></app-check-box>
    </div>
    <h4 class="pt-2 noSelect">{{ product?.name | titlecase }}</h4>
    <div *ngIf="product.supplier && userService.user?.organization?.isAllowViewSupplier" class="fxRow fxColumnCenter pt6">
        <img  *ngIf="product.supplier?.logoUrl"
              [src]="product.supplier?.logoUrl"
              class="h30 w30 img-fluid rounded-circle border">
        <h6 class="colorSuccess" [class.ml12]="product.supplier?.logoUrl">{{ product.supplier?.name | titlecase }}</h6>
    </div>
    <h5 class="noSelect">{{ product.packing || '-' }}{{product.unitOfMeasurement}}</h5>
    <div *ngIf="product.isPremium" class="fxRow pb4" style="z-index: 1">
        <div class="py6 px12 backgroundPrimary colorWhite fontBold positionRelative font12 noSelect">
            {{'product_premium_label' | translate}}
            <div class="triangleTop positionAbsolute top0" style="right: -14px" ></div>
            <div class="triangleBottom positionAbsolute bottom0" style="right: -14px" ></div>
        </div>
    </div>
    <h4 *ngIf="userService.isAllowViewPrice" class="noSelect">{{ getPrice(product) | currency: productService.Currency.currency:'symbol' }}</h4>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
