import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LoginService} from '../../login/service/login.service';
import * as _ from "lodash";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {FormBuilder} from "@angular/forms";
import {BasketService} from "../../shop/basket/service/basket.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {SideMenuService} from '../../side-menu/service/side-menu.service';
import {ClientSettingApiService} from "../../../service/client-setting-api-service/client-setting-api.service";
import {firstValueFrom} from "rxjs";
import { UserService } from 'src/app/native/user/service/user.service';
import {ApplicationService} from "../../../service/application-service/application.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  public isOnNoHeaderActionScreen: boolean;
  public noHeaderActionScreenRouteNameList: string[];
  public openSearch: boolean;

  // debounce when close cart right dialog
  debounce :any;

  isShowMyCart: boolean;
  isCloseMyCart: boolean;

  public showMyProfile: boolean;
  public profileClick: boolean;

  public isShowLanguageSettingDialog: boolean;
  public isChangeLanguageClicked: boolean;
  searchWord: string;
  public routerSubscribe;
  public isShowChangeLanguage: boolean;

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
      private applicationService: ApplicationService,
      private loginService: LoginService,
      public basketService: BasketService,
      public dialogService: DialogService,
      private renderer: Renderer2,
      public sideMenuService: SideMenuService,
      private router: Router,
      private formBuilder: FormBuilder,
      private activatedRoute: ActivatedRoute,
      private translatorService: TranslatorService,
      private clientSettingApiService: ClientSettingApiService,
      public userService: UserService
  ) {

    this.noHeaderActionScreenRouteNameList = ['privacy-policy', 'term-and-condition'];
    if (!activatedRoute.snapshot.url[0] || this.noHeaderActionScreenRouteNameList.includes(activatedRoute.snapshot.url[0].path)) {
      this.isOnNoHeaderActionScreen = true;
    }

    this.debounce = _.debounce(this.onCloseShoppingCart.bind(this), 150);
    if(!this.isOnNoHeaderActionScreen){
        this.basketService.getBasketItem();
        this.getClientSetting();
    }

    this.renderer.listen('window', 'click', (e: Event) => {
      // detect click outside to close profile dialog
      if (!this.profileClick) {
        this.showMyProfile = false;
      }
      this.profileClick = false;
    // detect click outside to close language dialog
      if (!this.isChangeLanguageClicked) {
        this.isShowLanguageSettingDialog = false;
      }
      this.isChangeLanguageClicked = false;
    });

    this.searchWord = '';
  }

  ngOnInit(): void {
    // clear search when go to home
    this.routerSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const routeList = this.router.url.split('/');
        if(!_.isEmpty(routeList[2]) && !routeList[2].includes('search') && _.isEmpty(routeList[3])){
          this.searchWord = '';
        }
      }});
    this.searchWord = this.activatedRoute.snapshot.queryParams['key'] ? this.activatedRoute.snapshot.queryParams['key'] : '';
  }

  async getClientSetting(): Promise<void>{
    try{
      const clientSetting = await firstValueFrom(this.clientSettingApiService.getClientSetting());
      this.isShowChangeLanguage = clientSetting.enableLanguage;
      this.applicationService.isEnableChangePacking = clientSetting.enableChangePacking;
    }catch(e){
      console.error('fail to get client setting', e);
    }
  }

  onLogout() {
    this.loginService.logout();
  }

  //open my cart right nav
  onShoppingCart() : void{
    this.isShowMyCart = true;
  }

  // delay close my cart
  onDebounceCloseShoppingCart(){
    this.isCloseMyCart = true;
    this.debounce();
  }

  // close my cart
  onCloseShoppingCart(): void {
    this.isShowMyCart = false;
    this.isCloseMyCart = false;
  }
  
  toggleTheme(): void {
    document.body.classList.toggle('dark');
  }

  //prevent close when click open
  preventCloseProfileOnClick() {
    this.profileClick = true;
  }

  //prevent close when click open
  preventLanguageSettingOnClick() {
    this.isChangeLanguageClicked = true;
  }
  //goto search product
  onSearch(){
    this.searchInput.nativeElement.blur();
    this.openSearch = false;
    if(this.searchWord.trim() === ''){
      const routeList = this.router.url.split('/');
      if(routeList[2].includes('search')){
        this.router.navigate(['/home']);
      }
    }else{
      this.router.navigate(['/home/search'],
          { queryParams: { key: this.searchWord.trim()} });
    }
  }

  // change language
  onChangeLanguage(language: string): void{
    this.translatorService.changeLanguage(language);
  }

  onGotoRecipe(): void{
    this.router.navigate(['/home/recipe']);
  }

  ngOnDestroy() {
    if (this.routerSubscribe) { this.routerSubscribe.unsubscribe(); }
  }
}
