import { Injectable } from '@angular/core';
import {BaseService} from '../../../../../service/base-service/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailApiService {

  constructor(
    private baseService: BaseService
  ) { }
  
  apiGetProductDetailById(productId): any {
    return this.baseService.get(`/v1/user/get/menuItemDetail/${productId}`);
  }
  
  apiGetLatestProduct(limit): any {
    return this.baseService.get(`/v1/user/get/latest/menuItem?limit=${limit}`);
  }

  apiGetProductList(next: number, query?): any {
    const queryString = this.baseService.buildQuery(query);
    return this.baseService.get(`/v1/user/get/menuItem/${next}/100${queryString}`);
  }
}
