import { Injectable } from '@angular/core';
import {BaseService} from "../base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MaterialApiService {

  constructor(private baseService: BaseService) { }


  getMaterialList(limit: number,next: number): Observable<any>{
    return this.baseService.get(`/v1/user/get/materialList/${next}/${limit}`);
  }
}
