<div class="h80vh w500 fxColumn noSelect px-4 py-2">

    <div class="mb-4 row justify-content-between align-items-center top-sec">
        <h3 class="col-11">{{ 'Pick Product' | translate }}</h3>
        <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
    </div>

    <!--search-->
    <div class="fullWidth p6">
        <input id="form-input-subject"
               [(ngModel)]="searchText"
               (input)="onSearch()"
               class="form-control"
               [placeholder]="'Search...' | translate"
        />
    </div>

    <!--body-->
    <div class="fxColumn fullHeight fullWidth scrollY my24 borderRadius p12"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         (scrolled)="onGetMoreItem()">
        <div *ngFor="let item of itemList" (click)="onSelectItem(item)"
             class="fxRow fullWidth fxRowSpaceBetween fxColumnCenter p12 cursorPointer backgroundOver mb12 borderRadius">
            <div class="fxRow fxColumnCenter">
                <app-product-avatar [iconSize]="16"
                                 [style]="{width: '40px', height: '40px'}" [src]="item.thumbnail"></app-product-avatar>
                <div class="ml12">
                    <div class="fontBold">{{item.name}}</div>
                </div>
            </div>
            <app-check-box [checked]="item.isSelected" [disable]="true"></app-check-box>
        </div>
        <div *ngIf="itemList.length <= 0 && !isLoading"
             class="fxRow fullWidth fxRowCenter fxColumnCenter">
            <div class="font18 noSelect">
                {{'No Data'}}
            </div>
        </div>
    </div>

    <!--footer-->
    <div class="fxRow fxRowEnd">
        <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
        <button class="btn btn-solid" (click)="onPick()" [disabled]="isDisable()">
            {{ 'Pick' | translate }}
        </button>
    </div>
</div>
