import {EventEmitter} from "@angular/core";

export type DialogOptions = {
  data?: any,
  disableClose?: boolean;
};

export class Dialog {
  name: string;
  isClosing: boolean;
  options: DialogOptions;
  eClose: EventEmitter<any>;

  constructor(name: string, options?: DialogOptions) {
    this.name = name;
    this.options = options!;
    this.eClose = new EventEmitter<any>();
  }

  close(data?: any, options?: DialogOptions): void {
    if (options?.disableClose) { return; }
    this.eClose.emit(data); }
}
