<div *ngIf="recipe" class="h60vh w500 fxColumn px-2 py-2">
    <div class="mb-4 fxRow justify-content-between align-items-center">
        <h3 class="col-11">
            Recipe Detail
        </h3>
        <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
    </div>
    <div class="fullHeight fadeIn">
        <div class="fxRow fxColumnCenter">
            <div class="w50p fxRow">
                <label class="form-label mr12">{{ 'Name:' | translate }}</label>
                <div class="fontSemiBold pl12">{{recipe.name ? recipe.name : '-'}}</div>
            </div>

            <div class="w50p fxRow fxColumnCenter">
                <label  class="form-label mr12">{{ 'Status: ' | translate }}</label>
                <div class="fxRow fxColumnCenter fxRowCenter">
                    <div class="status-container"
                         [class.status-draft] ="recipe.status === 'draft'"
                         [class.status-pending] ="recipe.status === 'pending'"
                         [class.status-accepted] ="recipe.status === 'accepted'"
                         [class.status-declined] ="recipe.status === 'declined'"
                    >{{recipe.status | uppercase}}</div>
                </div>
            </div>
        </div>

        <div class="mb-4 fxColumn fullHeight">
            <div class="fxRow fxColumnCenter">
                <label for="form-input-subject" class="font16 fontSemiBold mt12">{{ 'Ingredients' | translate }}</label>
            </div>
            <div *ngIf="recipe?.materialList?.length <= 0" class="h150 fxRow fxRowCenter fxColumnCenter">{{'No Ingredient'}}</div>
            <div *ngIf="recipe?.materialList?.length > 0" class="fxRow fxColumnCenter fxRowSpaceBetween mb12">
                <div class="fontBold w60p pl12 font12">{{'Name'}}</div>
                <div class="fontBold w20p fxRow fxRowCenter font12">{{'Qty'}}</div>
                <div class="fontBold w20p fxRow fxRowCenter font12">{{'Measure'}}</div>
            </div>
            <div *ngIf="recipe?.materialList?.length > 0" class="scrollY borderRadius h300" >
                <div *ngFor="let material of recipe.materialList; let index = index;"
                     style="gap: 12px">
                    <div class="fxRow fxColumnCenter fxRowSpaceBetween py6 borderRadius mb12" style="border-bottom: 1px solid var(--divider-color);">
                        <div class="fontSemiBold w60p pl12">{{material.itemList[0]?.name ? material.itemList[0]?.name : '-'}}</div>
                        <div class="fontSemiBold w20p fxRow fxRowCenter">{{material.itemList[0]?.qty ? material.itemList[0]?.qty : '-'}}</div>
                        <div class="fontSemiBold w20p fxRow fxRowCenter">{{material.itemList[0]?.unitOfMeasurement ? material.itemList[0]?.unitOfMeasurement : '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
