<div class="w500 h80vh fxColumn px-4 py-2">
    <div class="fullHeight fxColumn">
        <div class="mb-4 row justify-content-between align-items-center top-sec">
            <h3 class="col-11">{{ 'Delivery Info'| translate }}</h3>
            <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
        </div>
        <div class="fxColumn">
            <div class="colorGray">{{'check_out_delivery_date_label'| translate}}:</div>
            <div class="fxRow fxRowCenter fxColumnCenter">
                <div class="fxColumn">
                    <div class="w160 h50 fxRow fxRowCenter fxColumnCenter p12 my6 border borderRadius">
                        <div  *ngIf="!deliveredAt" class="fontSemiBold">{{'check_out_asap_label' | translate}}</div>
                        <div  *ngIf="deliveredAt" class="fontSemiBold">{{deliveredAt| date: 'dd/MM/yyyy hh:mm a'}}</div>
                    </div>
                    <input id="deliveredAt" style="visibility: hidden; height: 0; width: 0; "
                           type="datetime-local" [(ngModel)]="deliveredAt" [min]="getFormatDateTimeNow()">
                </div>
                <button  class="btn btn-solid ml12 w166" (click)="onChangeDate()">
                    {{(deliveredAt ? 'check_out_change_button' : 'check_out_choose_date_button')| translate}}
                </button>
                <button class="btn btn-solid ml12 w128" [class.hidden]="!deliveredAt"  (click)="onResetDeliveryDate()">
                    {{'check_out_reset_date_button'| translate}}
                </button>
            </div>
        </div>
        <div class="mb-4 fxColumn" style="height: calc(100vh - 393px);">
            <div class="colorGray mt6">
                {{'Delivery Address'| translate}}</div>
            <div class="order-success-sec fullHeight scrollY">
                <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
                <!--empty address-->
                <div *ngIf ="!orderAddressList[0] && !isLoading" class="colorGray font20 fontMedium py12 noSelect
                             fxRow fxRowCenter mt36">
                    {{'check_out_no_address_label'| translate}}</div>
                <!--address list-->
                <div *ngIf ="orderAddressList[0] && !isLoading">
                    <div class="pr12 fadeIn">
                        <div *ngFor="let address of orderAddressList" class="fxRow fxColumnCenter
                                                p12 my6 borderNone borderRadius cursorPointer"
                             [class.borderPrimary]="address._id === selectedOrderAddress._id"
                             (click)="onSelectOrderAddress(address)">
                            <div *ngIf="address._id !== selectedOrderAddress._id" class="borderCircle
                                         border size20"></div>
                            <div *ngIf="address._id === selectedOrderAddress._id"
                                 class="outer-custom-radio-container size20">
                                <div class="inner-custom-radio-container size10"></div>
                            </div>
                            <app-product-avatar class="px12" [src]="address.photoUrl" [iconSize]= "40"
                                                [style]="{width: '50px', height: '50px'}"></app-product-avatar>
                            <div class="fxColumn">
                                <div class="fontBold">
                                    {{address.name}}
                                </div>
                                <div class="colorGray">
                                    {{address.address}}
                                </div>
                                <div *ngIf="address?.contactList[0]" class="colorGray">
                                    {{address.contactList[0].countryCode}} {{address.contactList[0].phone}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="fxRow fxRowEnd">
        <button class="btn btn-solid" (click)="onChooseAddress()" [disabled]="!selectedOrderAddress">
            {{ 'Refill' | translate }}
        </button>
    </div>
</div>
