<div *ngFor="let dialog of dialogService.dialogList"
     class="maxScreen positionFixed top0 left0 backgroundOverlay fxRow fxRowCenter fxColumnCenter zIndex999"
     (mousedown)="onClose($event, dialog, dialogDom)">
  <div #dialogDom [ngSwitch]="dialog.name" [class.dialog-animate-down]="dialog.isClosing" class="background color borderRadius dialogOpenAnimation" (mousedown)="onClickDialog($event)">
    <app-expense-list-dialog *ngSwitchCase="'expenseListDialog'" [dialog]="dialog"></app-expense-list-dialog>
    <app-confirm-dialog *ngSwitchCase="'confirmDialog'" [dialog]="dialog" ></app-confirm-dialog>
    <app-change-password-dialog *ngSwitchCase="'changePasswordDialog'" [dialog]="dialog"></app-change-password-dialog>
    <app-order-detail-dialog *ngSwitchCase="'orderDetailDialog'" [dialog]="dialog"></app-order-detail-dialog>
    <app-add-ticket-dialog *ngSwitchCase="'addTicketDialog'" [dialog]="dialog"></app-add-ticket-dialog>
    <app-add-recipe-dialog *ngSwitchCase="'addRecipeDialog'" [dialog]="dialog"></app-add-recipe-dialog>
    <app-add-material-dialog *ngSwitchCase="'addMaterialDialog'" [dialog]="dialog"></app-add-material-dialog>
    <app-info-dialog *ngSwitchCase="'infoDialog'" [dialog]="dialog" ></app-info-dialog>
    <app-pick-item-dialog *ngSwitchCase="'pickItemDialog'" [dialog]="dialog" ></app-pick-item-dialog>
    <app-select-address-dialog *ngSwitchCase="'selectAddressDialog'" [dialog]="dialog" ></app-select-address-dialog>
    <app-recipe-detail-dialog *ngSwitchCase="'recipeDetailDialog'" [dialog]="dialog" ></app-recipe-detail-dialog>
  </div>
</div>


