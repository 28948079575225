<div class="fadeIn" *ngIf="isLoaded">
    <!-- product listing section start -->
    <div class="collection-wrapper">
        <div class="fxRow">
            <div class="col-xl-auto">
                <app-side-menu [items]="categoryList"></app-side-menu>
            </div>
            <div (scroll)="onScroll($event)"
                 class="col-xl-auto add-recipe-main h-no-header tab-content"
                 id="v-pills-tabContent"
            >
                <div class="tab-pane fade show active px-3" id="grocery">
                    <div id="categoryScrollId"></div>
                    <ng-container *ngFor="let categoryItem of categoryItemList; let i = index;" >
                        <section *ngIf="!loadingProduct && categoryItem.itemList && categoryItem.itemList[0]">
                            <div class="title8">
                                <div class="fxRow fxRowSpaceBetween fxColumnCenter">
                                    <div class="fxColumn">
                                        <h2>{{ categoryItem?.label || '-' }}</h2>
                                        <p *ngIf="categoryItem.description">{{ categoryItem.description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-sm-4 g-3">
                                <app-product-select
                                        *ngFor="let product of categoryItem.itemList"
                                        [product]="product"
                                        [loader]="loadingProduct"
                                        (eItemClick)="onClickItem($event)"
                                        [isSelected]="isItemSelected(product)"
                                        class = "w25p"
                                ></app-product-select>
                            </div>
                        </section>
                    </ng-container>

                </div>

                <section>
                    <div class="product-infinitescroll">
                        <div class="theme-paggination-block">
                            <div class="container-fluid p-0">
                                <div class="fxRow fxRowCenter">
                                    <div class="text-center col-sm-12">
                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                            <img *ngIf="isNext || loadingProduct" class="loading-icon" src="./assets/image/img/product/loader.gif" >
                                            <div *ngIf="!isNext && !loadingProduct" class="title8">
                                                <p>{{ 'home_collection_no_more_product' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <app-footer></app-footer>
                </section>
                <div class="positionAbsolute bottom0 left50p pb24">
                    <button  class="btn btn-solid" (click)="onCustomProduct()">
                        {{ 'Add new ingredient' | translate }}
                    </button>
                </div>
            </div>
            <div class="add-recipe-list fxColumn p24">
                <div class="mb-3">
                    <label for="form-input-name" class="form-label">{{ 'Name' | translate }}</label>
                    <input id="form-input-name"
                           [(ngModel)]="recipe.name"
                           [class.is-invalid]="error.name"
                           (input)="onInputChange('name')"
                           class="form-control"
                           [placeholder]="'Name' | translate"
                    />
                </div>
                <div class="mb-4 fxColumn fullHeight">
                    <div class="fxRow fxColumnCenter">
                        <label for="form-input-subject" class="form-label">{{ 'Ingredient' | translate }}</label>
                        <label *ngIf="error.materialList" for="form-input-subject" class="form-label colorDanger ml12 font12">{{ 'Please add at least one ingredient' | translate }}</label>
                    </div>
                    <div *ngIf="recipe?.materialList?.length <= 0" class="h150 fxRow fxRowCenter fxColumnCenter">{{'No Data'}}</div>
                    <div *ngIf="recipe?.materialList?.length > 0" class="scrollY p12 borderRadius" [class.borderDanger]="error.materialList" style="height: calc(100vh - 415px);">
                        <div *ngFor="let material of recipe.materialList; let index = index;" class="fxRow fxColumnCenter fxRowSpaceBetween backgroundOver py6 px12 borderRadius" [class.mb12]=" index !== materialList.length - 1"
                             style="gap: 12px">
                            <div *ngIf="!material.itemList[0]?.isNeedValidate" class="fontSemiBold w40p">{{material.itemList[0].name}}</div>
                            <input *ngIf="material.itemList[0]?.isNeedValidate" [(ngModel)]="material.itemList[0].name"
                                   [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].name" placeholder="Name"
                                   class="form-control w35p" (input)="onInput(index ,'name')"
                            />
                            <input [(ngModel)]="material.itemList[0].qty" type="number" min="1" pattern="^[0-9]" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                   [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].qty" placeholder="Amount" (input)="onInput(index ,'qty')"
                                   class="form-control w25p"
                            />
                            <select [(ngModel)]="material.itemList[0].unitOfMeasurement" [class.is-invalid]="errorMaterialItem[index] && errorMaterialItem[index].measure"
                                    (change)="onInput(index ,'measure')" class="form-control w30p">
                                <option *ngFor="let uom of getItemMeasure(material.itemList[0].packing)" [value]="uom">{{uom}}</option>
                            </select>

                            <div class="w10p fxRow fxRowCenter fxColumnCenter">
                                <div class="cursorPointer" (click)="onRemoveItem(material, index)">
                                    <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                              class="size20"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fxRow fxRowEnd">
                    <button *ngIf=" recipeId === ''" class="btn btn-solid" (click)="onAddRecipe()" [disabled]="isCreating || recipe?.materialList?.length <= 0">
                        {{ 'Create' | translate }}
                    </button>
                    <button *ngIf="recipeId !== ''" class="btn btn-solid" (click)="onUpdateRecipe()" [disabled]="isCreating || recipe?.materialList?.length <= 0">
                        {{ 'Update' | translate }}
                    </button>
                    <app-loader-overlay *ngIf="isCreating"></app-loader-overlay>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoaded">
    <!-- product listing section start -->
    <div class="collection-wrapper">
        <div class="row">
            <div class="col-xl-auto d-none d-xl-block p-0">
                <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '100vh' }"></ngx-skeleton-loader>
            </div>

            <div class="col px-3">

                <div class="pt24">
                    <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '10', height: '300px' }"></ngx-skeleton-loader>
                </div>

                <section>
                    <div>
                        <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '20px', 'width': '150px' }"></ngx-skeleton-loader>
                    </div>

                    <div class="mt6">
                        <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '15px', 'width': '250px' }"></ngx-skeleton-loader>
                    </div>

                    <div class="row g-sm-4 g-3">
                        <app-skeleton-product-box
                                *ngFor="let product of [].constructor(10)"
                                class="col-xxl-2 col-xl-3 col-md-4 col-6"
                        ></app-skeleton-product-box>
                    </div>

                </section>
            </div>

        </div>
    </div>
    <!-- product listing section end -->
</div>


