<div class="h-no-header fadeIn scrollY" (scroll)="onScroll($event)">
    <app-breadcrumb [breadcrumb]="breadCrumb"></app-breadcrumb>
    <section class="section-b-space">
        <div class="container" (scroll)="onScroll($event)">
            <div class="fxRow fxRowEnd">
                <button class="btn btn-solid" (click)="onAddRecipe()">
                    {{'Add New Recipe'| translate}}
                </button>
            </div>
            <div class="divider my24"></div>
            <div>
                <div *ngIf="isLoading && !(recipeList.length > 0)"class="fxRow fxRowCenter fxColumnCenter h200">
                    <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
                </div>
                <div *ngIf="!isLoading && !(recipeList.length > 0)" class="fxRow fxRowCenter fxColumnCenter h200">
                    <div class="font20 colorGray">{{'No Data'| translate}}</div>
                </div>
                <div *ngIf="recipeList.length > 0" class="table-responsive-xl">
                    <table class="table cart-table verticalAlignCenter">
                        <thead>
                        <tr class="table-head">
                            <th scope="col">
                                {{'Name' | translate}}</th>
                            <th class="textCenter" scope="col">{{'Status' | translate}}</th>
                            <th class="textCenter" scope="col">{{'Last Update' | translate}}</th>
                            <th class="textCenter" scope="col">{{'Action' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let recipe of recipeList" class="cursorPointer backgroundPrimaryHover" (click)="onGoDetail(recipe)">
                            <td class="">
                                {{recipe.name}}
                            </td>
                            <td class="textCenter">
                                <div class=" h50 fxRow fxColumnCenter fxRowCenter">
                                    <div class="status-container"
                                         [class.status-draft] ="recipe.status === 'draft'"
                                         [class.status-pending] ="recipe.status === 'pending'"
                                         [class.status-accepted] ="recipe.status === 'accepted'"
                                         [class.status-declined] ="recipe.status === 'declined'"
                                    >{{recipe.status | uppercase}}</div>
                                </div>
                            </td>
                            <td class="textCenter">
                                <div class="fxColumn fxRowCenter">
                                    {{recipe.updatedAt | date:'dd MMM, yyyy hh:mm a'}}
                                </div>
                            </td>
                            <td class="textCenter">
                                <div class="fxRow fxColumnCenter fxRowCenter gap12">
                                    <div *ngIf="['pending', 'draft'].includes(recipe.status)" class="cursorPointer" (click)="onEdit($event, recipe)">
                                        <svg-icon src="./assets/image/icon/recipe/edit-icon.svg"
                                                  class="size18 colorGray"></svg-icon>
                                    </div>
                                    <div class="cursorPointer" (click)="onDelete($event, recipe)">
                                        <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                  class="size20 colorGray"></svg-icon>
                                    </div>
                                    <div *ngIf="recipe.status === 'accepted'" class="cursorPointer" (click)="onRefill($event, recipe)">
                                        <svg-icon src="./assets/image/icon/recipe/refill-icon.svg"
                                                  class="size18 colorGray"></svg-icon>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isLoading && recipeList.length > 0"class="fxRow fxRowCenter fxColumnCenter h50">
                    <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
                </div>
            </div>
        </div>
    </section>
    <section>
        <app-footer></app-footer>
    </section>
</div>

