import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";

@Component({
  selector: 'app-recipe-detail-dialog',
  templateUrl: './recipe-detail-dialog.component.html',
  styleUrls: ['./recipe-detail-dialog.component.scss']
})
export class RecipeDetailDialogComponent implements OnInit{
  // init
  @Input() dialog: Dialog;

  public title;
  public description;
  public recipe: any;

  constructor(
      private dialogService:DialogService,
      private translatorService: TranslatorService
  ){
    this.recipe = {};
  }

  async ngOnInit(): Promise<void> {
    this.recipe = this.dialog.options;
    this.title =  await this.translatorService.
    translate('Recipe Detail');
  }
  onOkay(){
    this.dialogService.close(this.dialog.name, this.dialog.options);
  }


}
