<div style="width: fit-content; height: fit-content;">
    <div class="fxRow fxColumnCenter cursorPointer" (click)="onCheck()">
        <div *ngIf="checked" class="backgroundPrimary p2" style="border-radius: 4px">
            <svg-icon *ngIf="checked" src="./assets/image/icon/shop/checkout/check-icon.svg"
                      class="colorWhite" [style.width.px]="size" [style.height.px]="size"></svg-icon>
        </div>
        <div *ngIf="!checked"  [style.border.px]="borderWidth" [style.borderStyle]="borderStyle"
             [style.width.px]="size + 4" [style.height.px]="size + 4"
             style="border-radius: 4px"></div>
        <div *ngIf="label" class="px6 noSelect" [class.fontBold]="isFontBold" [style.fontSize.px]="fontSize">{{label}}</div>
    </div>
</div>
