<div class="w400 noSelect">
    <div class="fxRow fxColumnCenter">
        <div class="dot"></div>
        <div class="font20 fontBold colorPrimary ml6">{{title}}</div>
    </div>
    <div class="colorGray font18 textBreak my24 mx12">{{description}}</div>
    <div class="fxRow fxColumnCenter fxRowEnd">
        <button class="button px12 py6 mx12 my6" (click)="onCancel()">{{'confirm_dialog_no_button_title'| translate}}</button>
        <button class="buttonPrimary px12 py6 mx12 my6" (click)="onOkay()">{{'confirm_dialog_okay_button_title'| translate}}</button>
    </div>
</div>
