import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {ToastrService} from "ngx-toastr";
import * as _ from "lodash";
import {firstValueFrom} from "rxjs";
import {MaterialApiService} from "../../../service/material-api-service/material-api.service";
import {RecipeApiService} from "../../../component/recipe/api/recipe-api.service";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {v4 as uuid} from 'uuid';
import {lastValueFrom} from "rxjs";
import {HomeSlider} from "../../../data/slider";
import {SideMenuService} from "../../../component/side-menu/service/side-menu.service";
import {CollectionApiService} from "../../../component/shop/collection/collection/api/collection-api.service";
import {ClientSettingApiService} from "../../../service/client-setting-api-service/client-setting-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SearchProductApiService} from "../../../component/search-product/api/search-product-api.service";

@Component({
  selector: 'app-add-recipe-dialog',
  templateUrl: './add-recipe-dialog.component.html',
  styleUrls: ['./add-recipe-dialog.component.scss']
})
export class AddRecipeDialogComponent implements OnInit, OnDestroy {
  @Input() dialog: Dialog;
  public categoryList: any;
  public categoryItemList: any;
  public trendingItemList: any;

  public selectedCategory: any;
  public selectedSubCategory: any;
  public selectedCategoryId: any;

  public HomeSliderConfig: any = HomeSlider;
  public carousels: any;
  public shopFeature: any;
  public searchWord: string;
  public recipeClone: any;

  public pagination: {
    page: number,
    limit: number,
  }
  public isNext: boolean;

  public isLoaded: boolean;
  public loadingProduct: boolean;
  public stopLoadMore: boolean;

  public eMenuSelectSub: any;

  public announcement: string;


  public isCreating: boolean;
  public error: any;
  public materialList: any[];
  public recipe: any;
  public errorMaterialItem: any;

  public paramSub: any;

  public itemMeasureSetting: any;

  constructor(
      public sideMenuService: SideMenuService,
      private collectionApiService: CollectionApiService,
      private clientSettingApiService: ClientSettingApiService,
      private translatorService: TranslatorService,
      private toasterService: ToastrService,
      private materialApiService: MaterialApiService,
      private recipeApiService: RecipeApiService,
      private dialogService: DialogService,
      private route: ActivatedRoute,
      private router: Router,
      private searchProductApiService: SearchProductApiService,
  ) {
    this.sideMenuService.isHomeScreen = true;

    this.pagination = {
      page: 1,
      limit: 48
    }
    this.eMenuSelectSub = this.sideMenuService.eMenuSelect.subscribe(() => {
      this.selectedCategory = this.sideMenuService.selectedMenu;
      this.selectedSubCategory = this.sideMenuService.selectedSubMenu;
      this.getCategoryProduct();
      const element = document.getElementById('categoryScrollId');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    })

    this.announcement = '';

    this.onScroll = _.throttle(this.onScroll, 300);

    this.error = {};
    this.errorMaterialItem = [];
    this.materialList = [];
    this.recipe = {name: '', itemList: [], materialList: [], status: ''};

    this.itemMeasureSetting = {};
    this.searchWord = '';
    this.recipeClone = {};

    this.onSearch = _.debounce(this.onSearch, 500);
  }

  async ngOnInit() {
    if(!_.isEmpty(this.dialog?.options?.data)){
      this.recipe = _.cloneDeep(this.dialog.options.data);
    }
    this.recipeClone = _.cloneDeep(this.recipe);
    await this.getProductList();
    this.getShopFeature();
    await this.getCarousel();
    await this.getAnnouncement();
    await this.getTrendingItem();
    await this.getItemMeasureSetting();
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.eMenuSelectSub.unsubscribe();
    if (this.paramSub) { this.paramSub.unsubscribe(); }
  }

  async getCarousel(): Promise<void> {
    try {
      this.carousels = await lastValueFrom(this.collectionApiService.apiGetHomeCarousel());
    } catch (e) {
      console.error('get carousel failed', e);
    }
  }

  async getTrendingItem(): Promise<void> {
    try {
      this.trendingItemList = await lastValueFrom(this.collectionApiService.apiGetHomeTrending());
    } catch (e) {
      console.error('get trending item failed', e);
    }
  }

  async getProductList(): Promise<void> {
    try {
      if (!this.selectedCategoryId) { this.selectedCategoryId = 'ALL'; }
      const next = (this.pagination.page - 1) * this.pagination.limit;
      // const response = await lastValueFrom(this.collectionApiService.apiGetHomeItem(next, this.pagination.limit, this.selectedCategoryId));
      const response = await firstValueFrom(this.searchProductApiService.searchProduct(24, next, {search: this.searchWord},
          this.selectedSubCategory?._id ?? this.selectedCategory?._id ?? ''));

      if (!this.categoryList) {
        this.categoryList = response.categoryList;
      }

      if (this.pagination.page === 1) {
        this.categoryItemList = [];
      }

      for (const category of response.list) {
        const categoryItem = this.categoryItemList.find(categoryItem => categoryItem._id === category._id);
        if (categoryItem && categoryItem.itemList) {
          categoryItem.itemList = categoryItem.itemList.concat(category.itemList);
        } else {
          this.categoryItemList.push(category);
        }
      }

      this.isNext = response.next !== null;
    } catch (e) {
      console.error('get latest product failed!', e);
    }
  }

  async getCategoryProduct(): Promise<void> {
    // this.loadingProduct = true;
    //
    // const dom = document.getElementById('v-pills-tabContent');
    // dom.scrollTo({top: 0});

    this.pagination.page = 1;

    this.selectedCategoryId = this.selectedSubCategory ? this.selectedSubCategory._id : this.selectedCategory._id;
    await this.getProductList();
    this.loadingProduct = false;
  }

  async onScroll(e) {
    const dom = e.target;
    if (dom.scrollHeight - (dom.scrollTop + dom.offsetHeight) < 800) {
      if (this.isNext && !this.loadingProduct && !this.stopLoadMore) {
        this.pagination.page += 1;
        await this.getProductList();
        this.stopLoadMore = true;
        setTimeout(() => {
          this.stopLoadMore = false;
        }, 1000);
      }
    }
  }

  private async getAnnouncement(): Promise<void> {
    try {
      const clientSetting = await firstValueFrom(this.clientSettingApiService.getClientSetting());
      this.announcement = clientSetting.announcement;
    }catch(e){
      console.error("fail to get client setting", e);
    }
  }
  async getItemMeasureSetting(): Promise<void>{
    try {
      this.itemMeasureSetting = await firstValueFrom(this.recipeApiService.getItemMeasureSetting());
    }catch(e){
      console.error("fail item measure setting", e);
    }
  }

  private async getShopFeature(): Promise<void> {
    try {
      this.shopFeature = await firstValueFrom(this.collectionApiService.apiGetShopFeature());
    }catch(e){
      console.error("fail to get shop feature", e);
    }
  }

  public async onSaveDaft() : Promise<void> {
    this.isCreating = true;
    try {
      this.recipe.status = 'draft';
      const response = await firstValueFrom(this.recipeApiService.createRecipe(this.recipe));
      const toasterText = await this.translatorService.translate('Recipe is draft successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.recipeClone = _.cloneDeep(this.recipe);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Draft recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to draft recipe', e);
    }
    this.isCreating = false;
  }

  public async onAddRecipe() : Promise<void> {
    if (!this.validateItem()) { return; }
    this.isCreating = true;
    try {
      this.recipe.status = 'pending';
      const response = await firstValueFrom(this.recipeApiService.updateRecipe(this.recipe._id, this.recipe));
      const toasterText = await this.translatorService.translate('Recipe is created successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.onClose(this.recipe);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Create recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to create recipe', e);
    }
    this.isCreating = false;
  }
  public async onUpdateRecipe(): Promise<void>{
    if (!this.validateItem()) { return; }
    this.isCreating = true;
    try {
      const response = await firstValueFrom(this.recipeApiService.updateRecipe(this.recipe._id, this.recipe));
      const toasterText = await this.translatorService.translate('Recipe is updated successfully');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.onClose(this.recipe);
    } catch(e) {
      const toasterText = await this.translatorService.translate('Update recipe fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to create recipe', e);
    }
    this.isCreating = false;
  }

  public onInputChange(name: string): void {
    this.error[name] = false;
  }

  private validateItem(): boolean {

    this.error.name = _.isEmpty(this.recipe.name.trim());
    // this.error.itemList = _.isEmpty(this.recipe.itemList);
    this.error.materialList = _.isEmpty(this.recipe.materialList);
    this.errorMaterialItem = [];
    let index = 0;
    for (const material of this.recipe.materialList) {
      if (material.itemList[0].name.trim() === '' || _.isEmpty(String(material.itemList[0].qty).trim()) || !material.itemList[0]?.qty
          || _.isEmpty(String(material.itemList[0].unitOfMeasurement.trim()))) {
        const e = {
          name: material.itemList[0].name.trim() === '',
          qty: _.isEmpty(String(material.itemList[0].qty).trim()) || !material.itemList[0]?.qty,
          measure: _.isEmpty(String(material.itemList[0].unitOfMeasurement)),
        };
        this.errorMaterialItem.splice(index, 0, e);
      } else {
        this.errorMaterialItem.splice(index, 0, {name: false, qty: false, measure: false});
      }
      index ++;
    }
    console.log(this.errorMaterialItem);
    for (const e of this.errorMaterialItem){
      if (e.name || e.qty || e.measure){
        return false;
      }
    }
    for (const key in this.error) {
      if (this.error.hasOwnProperty(key)) {
        if (this.error[key]) {
          return false;
        }
      }
    }
    return true;
  }

  onRemoveItem(material: any, index: number): void{
    this.recipe.materialList = this.recipe.materialList.filter(m => m.itemList[0]._id !==  material.itemList[0]._id);
    this.errorMaterialItem.splice(index, 1);
  }

  onInput(index: number , key: string): void {
    if (key === 'name' && this.errorMaterialItem[index] ){
      this.errorMaterialItem[index].name = false;
    }
    if (key === 'qty' && this.errorMaterialItem[index]){
      this.errorMaterialItem[index].qty = false;
    }
    if (key === 'measure' && this.errorMaterialItem[index]){
      this.errorMaterialItem[index].measure = false;
    }
  }
  onAddMaterial(): void{
    const pickItemDialog =  this.dialogService.open('pickItemDialog', {data: {excludeIdList: this.recipe?.materialList.map(material =>material.itemList[0]._id)}});
    const pickItemSub = pickItemDialog.eClose.subscribe(selectedItemList => {
      if (selectedItemList) {
        const newAddItemList = {itemList: selectedItemList.map(item => ({
            _id: item._id,
            name: item.name,
            unitOfMeasurement: item.unitOfMeasurement ?? '',
            qty: 1
          }))};
        this.recipe.materialList = this.recipe.materialList.concat(newAddItemList);
        this.onInputChange('materialList');
      }
      pickItemSub.unsubscribe();
    });
  }
  onCustomProduct(): void {
    const material = {itemList: [
        {
          _id: uuid(),
          name: '',
          qty: '',
          unitOfMeasurement: '',
          isNeedValidate: true,
          packing: 'packing'
        }
      ]};
    this.recipe.materialList.push(material);
    this.onInputChange('materialList');
  }

  onClickItem(event): void{
    if(event.isAdd){
      const newAddItem = {
        itemList:[{
          _id: event.item._id,
          name: event.item.name,
          unitOfMeasurement: event.item.unitOfMeasurement ?? '',
          packing: event.item.packing ?? '',
          qty: 1
        }]
      };
      this.recipe.materialList.push(newAddItem);
      this.onInputChange('materialList');
    } else{
      this.recipe.materialList = this.recipe.materialList.filter(material => material.itemList[0]._id !== event.item._id);
    }

  }
  isItemSelected(item): boolean{
    return this.recipe.materialList?.some(i=> i.itemList[0]._id === item._id);
  }

  getItemMeasure(measure: string): string[]{
    let matchedObject = this.itemMeasureSetting.itemMeasureList.find(m => m.label === measure);
    if (matchedObject) {
      return matchedObject.subMeasureList.map(sub => sub.label);
    }
    return this.itemMeasureSetting.itemMeasureList.map(measure => measure.subMeasureList.map(sub => sub.label)).flat();
  }
  onSearch(): void{
    this.getProductList().then();
  }

  onClose(data?: any): void{
    this.dialog.close(data, {disableClose: false});
  }

  onCancel(): void{
    if(this.isChangeData()){
      const confirmDialog = this.dialogService.open('confirmDialog', {
        data: {
          title: 'Confirm',
          description: 'Are you want save draft this recipe ?'
        },
        disableClose: true
      });
      const confirmSub = confirmDialog.eClose.subscribe(async (data) => {
        if (data) {
          await this.onSaveDaft();
        }
        this.onClose(this.recipe);
        confirmSub.unsubscribe();
      });
    } else{
      this.onClose(this.recipe);
    }
  }

  isChangeData(): boolean{
    return !_.isEqual(this.recipe, this.recipeClone);
}

isHaveCustomProduct(): boolean{
    return !_.isEmpty(this.recipe.materialList.filter(material => material.itemList[0]?.isNeedValidate));

}

}


