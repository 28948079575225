import {Component, OnInit} from '@angular/core';
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {Breadcrumb} from "../../../native/breadcrumb/model/breadcrumb";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {Router} from "@angular/router";
import {RecipeApiService} from "../api/recipe-api.service";
import {firstValueFrom} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit{
  public breadCrumb: Breadcrumb;
  public isLoading: boolean;
  public next: number;
  public isNext: boolean;
  public recipeList : any[];

  constructor(
      private translatorService: TranslatorService,
      private dialogService: DialogService,
      private router: Router,
      private recipeApiService: RecipeApiService,
      private toasterService: ToastrService,
  ){
    this.recipeList= [];
    this.next = 0;
  }

  async ngOnInit() {
    await this.initBreadcrumb();
    await this.getRecipeList();
  }
  private async initBreadcrumb() {
    this.breadCrumb = new Breadcrumb({
      title: await this.translatorService.translate('ticket_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('RECIPE')
        }
      ]
    });
  }
  private async getRecipeList() : Promise<void> {
    try {
      this.isLoading = true;
      const {data} =  await firstValueFrom(this.recipeApiService.getRecipeList(this.next,15));
      this.recipeList = this.recipeList.concat(data.list);
      this.next = data.next;
      this.isNext = data.next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get recipe', e);
    }
  }

  public async onScroll(e) {
    const dom = e.target;
    if (dom.scrollHeight - (dom.scrollTop + dom.offsetHeight) < 400) {
      if (this.isNext && !this.isLoading) {
        await this.getRecipeList();
      }
    }
  }
  public capitalizeFirstLetter(text: string): string {
    text = text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
    return text;
  }

  onAddRecipe(): void{
    const addRecipeDialog = this.dialogService.open('addRecipeDialog', {disableClose: true});
    const addRecipeSub = addRecipeDialog.eClose.subscribe(async data => {
      this.next = 0;
      this.recipeList = [];
      await this.getRecipeList();
      addRecipeSub.unsubscribe();
    });
  }
  onEdit(e: any, recipe: any){
    e.stopPropagation();
    const addRecipeDialog = this.dialogService.open('addRecipeDialog', {data: recipe, disableClose: true} );
    const addRecipeSub = addRecipeDialog.eClose.subscribe(data => {
      if (data) {
        const index = this.recipeList.findIndex(rec => rec._id === recipe._id);
        if (index !== -1) {
          this.recipeList[index] = data;
        }
      }
      addRecipeSub.unsubscribe();
    });
  }
  onDelete(e: any, recipe: any){
    e.stopPropagation();
    const confirmDialog = this.dialogService.open('confirmDialog', {
      data: {
        description: 'Are you sure you want to delete this recipe ?'
      }
    });
    const confirmSub = confirmDialog.eClose.subscribe(async (data) => {
      if (data) {
        try {
          await firstValueFrom(this.recipeApiService.deleteRecipe(recipe._id));
          this.recipeList = this.recipeList.filter(rec => rec._id !== recipe._id);
          const toasterText = await this.translatorService.translate('Recipe have been deleted');
          this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
        }catch (e) {
          const toasterText = await this.translatorService.translate('Delete Recipe fail');
          this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
        }
      }
      confirmSub.unsubscribe();
    });
  }
  onRefill(e: any,recipe: any){
    e.stopPropagation();
    const selectAddressDialog = this.dialogService.open('selectAddressDialog',);
    const selectAddressSub = selectAddressDialog.eClose.subscribe(async (deliveryInfo) => {
      if (deliveryInfo) {
        try {
          await firstValueFrom(this.recipeApiService.refillRecipe(recipe._id, deliveryInfo));
          const toasterText = await this.translatorService.translate('Refill succeed');
          this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
        }catch (e) {
          const toasterText = await this.translatorService.translate('Refill fail');
          this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
        }
      }
      selectAddressSub.unsubscribe();
    });
  }
  onGoDetail(recipe: any): void{
    this.dialogService.open('recipeDetailDialog', recipe);
  }
}
